
export const columns = [
  {
    title: "序号",
    dataIndex: "index",
    customRender: (value, item, index) => index + 1,
    fixed: "left",
    width: 60,
  },
  {
    title: "产品编号",
    dataIndex: "material_number",
    width: 150,
    fixed: "left",
  },
  {
    title: "产品名称",
    dataIndex: "material_name",
    width: 150,
    fixed: "left",
  },
  {
    title: "剩余数量",
    dataIndex: "remain_quantity",
    width: 80,
  },
  {
    title: "收货数量",
    dataIndex: "receipt_quantity",
    scopedSlots: { customRender: "receipt_quantity" },
  },
  {
    title: "单位",
    dataIndex: "material_unit",
    width: 80,
  },
  {
    title: "计费量",
    dataIndex: "charge_value",
    scopedSlots: { customRender: "charge_value" },
  },
  {
    title: "计费单位",
    dataIndex: "charge_unit_display",
  },
  {
    title: "包装规格",
    dataIndex: "material_spec",
    width: 80,
  },
  {
    title: "质检状态",
    dataIndex: "status",
    scopedSlots: { customRender: "status" },
  },
  {
    title: "批次编号",
    dataIndex: "batch_number",
    scopedSlots: { customRender: "batch_number" },
  },
  {
    title: "启用保质期",
    dataIndex: "enable_shelf_life",
    customRender: (value, item, index) => {
      return value ? "启用" : "关闭";
    },
  },
  {
    title: "保质期天数",
    dataIndex: "shelf_life_days",
  },
  {
    title: "生产日期",
    dataIndex: "production_date",
    scopedSlots: { customRender: "production_date" },
  },
  {
    title: "操作",
    dataIndex: "action",
    scopedSlots: { customRender: "action" },
    width: 60,
    fixed: "right",
  },
];
